var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-config-provider",
    { attrs: { locale: _vm.locale } },
    [
      _c("a-pagination", {
        attrs: {
          simple: _vm.simple,
          total: _vm.count,
          "show-total": function (total) {
            return "共 " + total + " 条"
          },
          "show-size-changer": "",
          "show-quick-jumper": "",
        },
        on: { change: _vm.onChange, showSizeChange: _vm.onChangeSize },
        model: {
          value: _vm.currentNum,
          callback: function ($$v) {
            _vm.currentNum = $$v
          },
          expression: "currentNum",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }