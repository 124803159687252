import request from "@/api/server/index"

// 产品列表
/*
    param num - 当前页码
    param count - 每页显示数据条数
    param name - 列表搜索的产品名称
    param num1 - 接入协议id
    param num2 - 节点类型（1:直连设备，2:网关设备，3:网关子设备）
*/
export function getProductList(num, count, name, num1, num2) {
    return request({
        url: '/manager/product/page',
        method: 'post',
        data: {
            page: num,
            pageSize: count,
            productName: name,
            accessProtocolId: num1,
            nodeType: num2
        }
    })
}

// 产品列表-无分页
/*
    param type - 节点类型,不传参数查询所有类型（1:直连设备 2:网关设备 3:子设备）
*/
export function productList(type) {
    return request({
        url: "/manager/product/list",
        method: 'post',
        data: {
            nodeType: type
        }
    })
}

// 未绑定服务实例的产品列表-无分页
export function noServiceProductList() {
    return request({
        url: "/manager/product/noserver/list",
        method: 'post'
    })
}

// 产品类型
export function getProductType() {
    return request({
        url: '/manager/productCategory/list',
        method: 'post'
    })
}

// 产品删除
/*
    param id - 当前删除的产品id
*/
export function deleteProduct(id) {
    return request({
        url: `/manager/product/remove/${id}`,
        method: 'post'
    })
}

// 通过节点类型获取数据协议
/*
    param nodeType - 当前选中的节点类型值
*/
export function getDataAgreement(nodeType) {
    return request({
        url: `/manager/accessProtocol/getByNodeType/${nodeType}`,
        method: 'get'
    })
}

// 新增/修改产品
/*
    param name - 产品名称
    param city - 当前选中的城市名称
    param province - 当前选中的省份名称
    param id - 当前选中的产品类型id
    param node - 当前选中的节点类型值
    param accessId - 当前选中的接入协议id
    param mode - 当前选中的联网方式集合，字符串类型，多个用','隔开
    param model - 产品型号
    param fact - 产品厂商
    param desc - 产品描述
    param modelData - 物模型配置数据集合
    param productId - 产品id
*/
export function changeProduct(name, city, province, id, node, accessId, mode, model, fact, desc, productId) {
    return request({
        url: '/manager/product/saveOrUpdate',
        method: 'post',
        data: {
            productName: name,
            cityName: city,
            provinceName: province,
            categoryId: id,
            nodeType: node,
            accessProtocolId: accessId,
            networkMode: mode,
            productModel: model,
            manufacturer: fact,
            remark: desc,
            id: productId
        }
    })
}

// 更新物模型数据
/*
    param data - 当前设置的物模型配置
    param code - 当前产品code
    param arr - 当前设置的物模型列表数据
*/
export function updateModel(data, code, arr) {
    return request({
        url: `/manager/product/updateMetaData`,
        method: 'post',
        data: {
            metaData: data,
            productCode: code,
            metaDataObj: arr
        }
    })
}

// 产品详情
/*
    param id - 当前查询的产品id
*/
export function productDetail(id) {
    return request({
        url: `/manager/product/get/${id}`,
        method: 'get'
    })
}
