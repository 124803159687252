<template>
    <div class="productWrap">
        <!-- <p class="productTitle">
            我的产品
        </p> -->
        <ul class="productCon">
            <li style="display: flex">
                <a-select v-model="searchList.nodeType" placeholder="请选择节点类型" style="width: 340px" @change="onSelected">
                    <a-select-option value="1">
                        直连设备
                    </a-select-option>
                    <a-select-option value="2">
                        网关设备
                    </a-select-option>
                    <a-select-option value="3">
                        子设备
                    </a-select-option>
                </a-select>
                <a-select v-model="searchList.data" placeholder="请选择数据协议" not-found-content="请先选择节点类型" style="width: 340px; margin: 0 30px">
                    <a-select-option v-for="item in agreeList" :key="item.id" :value="item.id">
                        {{ item.accessProtocolName }}
                    </a-select-option>
                </a-select>
                <a-input v-model="searchList.keyWord" placeholder="请输入关键字搜索" />
                <a-button type="primary" style="margin-left: 30px" @click="onSearch">
                    查询
                </a-button>
            </li>
            <li>
                <a-button type="primary" @click="visible = true">
                    <a-icon type="plus" />
                    添加产品
                </a-button>
            </li>
        </ul>
        <a-table style="margin-bottom: 20px" row-key="id" :locale="{emptyText: '暂无数据'}" :columns="columns" :pagination="false" :data-source="data">
            <template slot="nodeType" slot-scope="text, record">
                <span>{{ record.nodeType | nodeTypeFilter }}</span>
            </template>
            <template slot="operation" slot-scope="text, record">
                <a href="javascript:void(0);" @click="gotoDetails(record)">详情</a>
                <a href="javascript:void(0);" style="margin: 0 20px" @click="equipManage(record)">设备管理</a>
                <a href="javascript:void(0);" @click="onDelete(record)">删除</a>
            </template>
        </a-table>
        <Pagination :count="total" :current-page="page" @handleChange="changePage" @handleChangeSize="changeSize" />
        <!-- 添加产品弹窗 -->
        <a-modal
            class="addProduct"
            :width="800"
            title="添加产品"
            ok-text="确认"
            cancel-text="取消"
            :visible="visible"
            @ok="onSubmit"
            @cancel="visible = false">
            <a-form-model
                ref="ruleForm"
                :model="form"
                :rules="rules">
                <a-form-model-item label="产品名称" prop="name">
                    <a-input
                        v-model="form.name"
                        placeholder="最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头" />
                </a-form-model-item>
                <a-form-model-item label="所属省市" prop="city">
                    <CityChoice :prov-val="provVal" :city-val="cityVal" @getProv="getProvInfo" @getCity="getCityInfo" />
                </a-form-model-item>
                <a-form-model-item label="产品类型" prop="type">
                    <div style="display: flex">
                        <a-select v-model="form.type" style="width: 43%;" placeholder="请选择产品类型" @change="chooseType">
                            <a-select-option v-for="item in productType" :key="item.id" :value="item.id">
                                {{ item.categoryName }}
                            </a-select-option>
                        </a-select>
                        <div style="display: flex; margin-left: 30px; align-items: center">
                            <a-button type="primary" :disabled="isShowModel" @click="modelVisible = true">
                                查看标准物模型
                            </a-button>
                            <a-radio :disabled="true" :checked="isChecked" style="margin-left: 10px">
                                自定义物模型
                            </a-radio>
                        </div>
                    </div>
                </a-form-model-item>
                <a-form-model-item label="节点类型" prop="nodeType" style="width: 43%; display: inline-block; margin-right: 4%">
                    <a-select v-model="form.nodeType" placeholder="请选择节点类型" @change="chooseNodeType">
                        <a-select-option value="1">
                            直连设备
                        </a-select-option>
                        <a-select-option value="2">
                            网关设备
                        </a-select-option>
                        <a-select-option value="3">
                            子设备
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="接入协议" prop="data" style="width: 43%; display: inline-block;">
                    <a-select v-model="form.data" not-found-content="请先选择节点类型" placeholder="请选择数据协议">
                        <a-select-option v-for="val in dataAgreement" :key="val.id" :value="val.id">
                            {{ val.accessProtocolName }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="联网方式" prop="delivery">
                    <a-checkbox-group v-model="form.delivery">
                        <a-checkbox value="蜂窝">
                            蜂窝
                        </a-checkbox>
                        <a-checkbox value="以太">
                            以太
                        </a-checkbox>
                        <a-checkbox value="NB">
                            NB
                        </a-checkbox>
                        <a-checkbox value="WIFI">
                            WIFI
                        </a-checkbox>
                        <a-checkbox value="其他">
                            其他
                        </a-checkbox>
                    </a-checkbox-group>
                </a-form-model-item>
                <a-form-model-item style="display: flex" label="产品厂商" prop="firm">
                    <a-input
                        v-model="form.firm"
                        placeholder="最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头" />
                </a-form-model-item>
                <a-form-model-item style="display: flex" label="产品型号" prop="model">
                    <a-input
                        v-model="form.model"
                        placeholder="最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头" />
                </a-form-model-item>
                <a-form-model-item style="display: flex" label="产品描述" prop="desc">
                    <a-textarea
                        v-model="form.desc" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
        <!-- 查看标准物模型弹窗 -->
        <a-modal
            :title="modelTitle"
            :footer="null"
            :visible="modelVisible"
            @cancel="modelVisible = false">
            <a-table :columns="modelColumns" :data-source="modelData" :locale="{emptyText: '暂无数据'}" row-key="id" size="middle" :pagination="false" />
        </a-modal>
    </div>
</template>

<script>
import Pagination from "@/components/Pagination"
import CityChoice from "@/components/CityChoice"
import { getProductList, getProductType, deleteProduct, getDataAgreement, changeProduct } from '@/api/product'
import { validatePass } from "@/utils/validate"
const columns = [
    {
        title: '产品名称',
        dataIndex: 'productName',
        key: 'name'
    },
    {
        title: '节点类型',
        dataIndex: 'nodeType',
        scopedSlots: { customRender: 'nodeType' }
    },
    {
        title: '接入协议',
        dataIndex: 'accessProtocolName',
        key: 'accessProtocolName'
    },
    {
        title: '创建时间',
        dataIndex: 'createTime',
        key: 'createTime'
    },
    {
        title: '操作',
        width: '200px',
        dataIndex: 'handle',
        scopedSlots: { customRender: 'operation' }
    },
]
const modelColumns = [
    {
        title: '功能类型',
        dataIndex: 'funType',
        key: 'funType'
    },
    {
        title: '功能名称',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: '标识符',
        dataIndex: 'sign',
        key: 'sign'
    },
    {
        title: '数据类型',
        dataIndex: 'dataType',
        key: 'dataType'
    }
]
const modelData = [
    {
        funType: '属性',
        id: 1,
        name: 'SIM卡',
        sign: 'SimCardType',
        dataType: 'STRING'
    },
    {
        funType: '属性',
        id: 2,
        name: 'SIM卡',
        sign: 'SimCardType',
        dataType: 'STRING'
    }
]
export default {
    name: "Index",
    components: {
        Pagination,
        CityChoice
    },
    data() {
        return {
            // 当前分页码
            page: 1,
            // 每页数据条数
            size: 10,
            // 列表数据总条数
            total: 0,
            // 搜索条件集合
            searchList: {
                nodeType: undefined,
                data: undefined,
                keyWord: ''
            },
            // 列表查询中的接入协议数据
            agreeList: [],
            // 查看标准物模型弹窗标题
            modelTitle: '',
            // 当前选中的省份及城市名称
            cityResult: '',
            provVal: '请选择省份',
            cityVal: '请选择城市',
            columns,
            modelColumns,
            modelData,
            data: [],
            // 产品类型列表
            productType: [],
            // 数据协议列表
            dataAgreement: [],
            // 添加产品的弹窗是否可见
            visible: false,
            // 查看标准物模型是否可见
            modelVisible: false,
            // 查看标准物模型按钮是否激活
            isShowModel: true,
            // 自定义物模型是否被选中
            isChecked: false,
            // 添加产品表单数据
            form: {
                name: '',
                type: undefined,
                nodeType: undefined,
                data: undefined,
                delivery: undefined,
                firm: '',
                model: '',
                desc: ''
            },
            // 添加产品表单验证规则
            rules: {
                name: [
                    { required: true, message: '请输入产品名称', trigger: 'blur' },
                    { validator: validatePass, trigger: 'blur' },
                ],
                type: [{ required: true, message: '请选择产品类型', trigger: 'change' }],
                nodeType: [
                    {
                        required: true,
                        message: '请选择节点类型',
                        trigger: 'change',
                    },
                ],
                data: [
                    { required: true, message: '请选择数据协议', trigger: 'change' },
                ],
                delivery: [{ required: true, message: '请选择联网方式', trigger: 'change' }],
                firm: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                model: [
                    { validator: validatePass, trigger: 'blur' }
                ]
            }
        }
    },
    created() {
        this.visible = this.$route.query.fromPage !== undefined
        this.getList(this.page, this.size)
        getProductType().then(res => {
            this.productType = res.data
        }).catch(error => {
            console.log(error)
        })
    },
    methods: {
        // 获取产品列表数据
        getList(page, size, name, id, type) {
            this.page = page
            getProductList(page, size, name, id, type).then(res => {
                let info = res.data
                this.data = info.records
                this.total = info.total
            }).catch(error => {
                console.log(error)
            })
        },
        // 选择分页
        changePage(page) {
            let info = this.searchList
            this.getList(page, this.size, info.keyWord.trim(), info.data, info.nodeType)
        },
        // 修改分页
        changeSize(size) {
            this.size = size
            let info = this.searchList
            this.getList(1, size, info.keyWord.trim(), info.data, info.nodeType)
        },
        // 产品搜索
        onSearch() {
            let info = this.searchList
            this.getList(1, this.size, info.keyWord.trim(), info.data, info.nodeType)
        },
        // 进入产品详情页
        gotoDetails(val) {
            this.$router.push(`/product-list/details?id=${val.id}`)
        },
        // 设备管理
        equipManage(val) {
            this.$router.push({path: '/equip-list', query: {id: val.code}})
        },
        // 删除
        onDelete(val) {
            this.$confirm({
                title: '您确认删除该项吗？',
                content: '删除该产品下的所有设备以后才可以删除产品',
                okText: '确定',
                centered: true,
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    deleteProduct(val.id).then(() => {
                        this.$message.success('产品删除成功！')
                        this.searchList.keyWord = ''
                        this.onSearch()
                    }).catch(error => {
                        console.log(error)
                    })
                }
            })
        },
        // 点击添加产品弹窗中的确定按钮
        onSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    let info = this.form,
                        prov = this.cityResult.split('-')[0],
                        city = this.cityResult.split('-')[1],
                        mode = info.delivery.join(',')
                    changeProduct(info.name, city, prov, info.type, info.nodeType, info.data, mode, info.model, info.firm, info.desc).then(() => {
                        this.visible = false
                        this.$message.success('产品添加成功！')
                        this.$refs.ruleForm.resetFields()
                        this.provVal = '请选择省份'
                        this.cityVal = '请选择城市'
                        this.onSearch()
                    }).catch(error => {
                        console.log(error)
                    })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 获取用户选择的省份数据
        getProvInfo(val) {
            if (this.provVal !== val) {
                this.cityVal = '请选择城市'
            } else {
                this.cityVal = this.cityVal || '请选择城市'
            }
            this.provVal = val
        },
        // 获取用户选择的省份及城市数据
        getCityInfo(val) {
            this.cityVal = val.split('-')[1]
            this.cityResult = val || ' - '
        },
        // 选择添加产品弹窗中的产品类型
        chooseType(id, name) {
            this.modelTitle = name.componentOptions.children[0].text.trim() + '标准物模型定义'
            let result = id === 0
            this.isChecked = result
            this.isShowModel = result
        },
        // 选择添加产品弹窗中的节点类型
        chooseNodeType(val) {
            getDataAgreement(val).then((res) => {
                let info = res.data
                this.dataAgreement = info
                this.form.data = info[0].id
            }).catch(error => {
                console.log(error)
            })
        },
        // 选择产品协议继续列表查询
        onSelected(val) {
            getDataAgreement(val).then((res) => {
                this.agreeList = res.data
            }).catch(error => {
                console.log(error)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .productWrap {
        margin: 20px 40px;
        .productTitle {
            font-size: 20px;
            font-weight: bold;
        }
        .productCon {
            display: flex;
            justify-content: space-between;
        }
    }
    ::v-deep .ant-pagination {
        text-align: right;
    }
    ::v-deep .ant-pagination-total-text {
        float: left;
    }
    .addProduct {
        ::v-deep .ant-form-item-control-wrapper {
            flex: 1;
        }
    }
</style>
