var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "productWrap" },
    [
      _c("ul", { staticClass: "productCon" }, [
        _c(
          "li",
          { staticStyle: { display: "flex" } },
          [
            _c(
              "a-select",
              {
                staticStyle: { width: "340px" },
                attrs: { placeholder: "请选择节点类型" },
                on: { change: _vm.onSelected },
                model: {
                  value: _vm.searchList.nodeType,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchList, "nodeType", $$v)
                  },
                  expression: "searchList.nodeType",
                },
              },
              [
                _c("a-select-option", { attrs: { value: "1" } }, [
                  _vm._v(" 直连设备 "),
                ]),
                _c("a-select-option", { attrs: { value: "2" } }, [
                  _vm._v(" 网关设备 "),
                ]),
                _c("a-select-option", { attrs: { value: "3" } }, [
                  _vm._v(" 子设备 "),
                ]),
              ],
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { width: "340px", margin: "0 30px" },
                attrs: {
                  placeholder: "请选择数据协议",
                  "not-found-content": "请先选择节点类型",
                },
                model: {
                  value: _vm.searchList.data,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchList, "data", $$v)
                  },
                  expression: "searchList.data",
                },
              },
              _vm._l(_vm.agreeList, function (item) {
                return _c(
                  "a-select-option",
                  { key: item.id, attrs: { value: item.id } },
                  [_vm._v(" " + _vm._s(item.accessProtocolName) + " ")]
                )
              }),
              1
            ),
            _c("a-input", {
              attrs: { placeholder: "请输入关键字搜索" },
              model: {
                value: _vm.searchList.keyWord,
                callback: function ($$v) {
                  _vm.$set(_vm.searchList, "keyWord", $$v)
                },
                expression: "searchList.keyWord",
              },
            }),
            _c(
              "a-button",
              {
                staticStyle: { "margin-left": "30px" },
                attrs: { type: "primary" },
                on: { click: _vm.onSearch },
              },
              [_vm._v(" 查询 ")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "a-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    _vm.visible = true
                  },
                },
              },
              [_c("a-icon", { attrs: { type: "plus" } }), _vm._v(" 添加产品 ")],
              1
            ),
          ],
          1
        ),
      ]),
      _c("a-table", {
        staticStyle: { "margin-bottom": "20px" },
        attrs: {
          "row-key": "id",
          locale: { emptyText: "暂无数据" },
          columns: _vm.columns,
          pagination: false,
          "data-source": _vm.data,
        },
        scopedSlots: _vm._u([
          {
            key: "nodeType",
            fn: function (text, record) {
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm._f("nodeTypeFilter")(record.nodeType))),
                ]),
              ]
            },
          },
          {
            key: "operation",
            fn: function (text, record) {
              return [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.gotoDetails(record)
                      },
                    },
                  },
                  [_vm._v("详情")]
                ),
                _c(
                  "a",
                  {
                    staticStyle: { margin: "0 20px" },
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.equipManage(record)
                      },
                    },
                  },
                  [_vm._v("设备管理")]
                ),
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.onDelete(record)
                      },
                    },
                  },
                  [_vm._v("删除")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("Pagination", {
        attrs: { count: _vm.total, "current-page": _vm.page },
        on: { handleChange: _vm.changePage, handleChangeSize: _vm.changeSize },
      }),
      _c(
        "a-modal",
        {
          staticClass: "addProduct",
          attrs: {
            width: 800,
            title: "添加产品",
            "ok-text": "确认",
            "cancel-text": "取消",
            visible: _vm.visible,
          },
          on: {
            ok: _vm.onSubmit,
            cancel: function ($event) {
              _vm.visible = false
            },
          },
        },
        [
          _c(
            "a-form-model",
            { ref: "ruleForm", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "产品名称", prop: "name" } },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder:
                        "最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头",
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "所属省市", prop: "city" } },
                [
                  _c("CityChoice", {
                    attrs: { "prov-val": _vm.provVal, "city-val": _vm.cityVal },
                    on: { getProv: _vm.getProvInfo, getCity: _vm.getCityInfo },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "产品类型", prop: "type" } },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "43%" },
                          attrs: { placeholder: "请选择产品类型" },
                          on: { change: _vm.chooseType },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        _vm._l(_vm.productType, function (item) {
                          return _c(
                            "a-select-option",
                            { key: item.id, attrs: { value: item.id } },
                            [_vm._v(" " + _vm._s(item.categoryName) + " ")]
                          )
                        }),
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "margin-left": "30px",
                            "align-items": "center",
                          },
                        },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: _vm.isShowModel,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.modelVisible = true
                                },
                              },
                            },
                            [_vm._v(" 查看标准物模型 ")]
                          ),
                          _c(
                            "a-radio",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { disabled: true, checked: _vm.isChecked },
                            },
                            [_vm._v(" 自定义物模型 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: {
                    width: "43%",
                    display: "inline-block",
                    "margin-right": "4%",
                  },
                  attrs: { label: "节点类型", prop: "nodeType" },
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择节点类型" },
                      on: { change: _vm.chooseNodeType },
                      model: {
                        value: _vm.form.nodeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "nodeType", $$v)
                        },
                        expression: "form.nodeType",
                      },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "1" } }, [
                        _vm._v(" 直连设备 "),
                      ]),
                      _c("a-select-option", { attrs: { value: "2" } }, [
                        _vm._v(" 网关设备 "),
                      ]),
                      _c("a-select-option", { attrs: { value: "3" } }, [
                        _vm._v(" 子设备 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { width: "43%", display: "inline-block" },
                  attrs: { label: "接入协议", prop: "data" },
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        "not-found-content": "请先选择节点类型",
                        placeholder: "请选择数据协议",
                      },
                      model: {
                        value: _vm.form.data,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "data", $$v)
                        },
                        expression: "form.data",
                      },
                    },
                    _vm._l(_vm.dataAgreement, function (val) {
                      return _c(
                        "a-select-option",
                        { key: val.id, attrs: { value: val.id } },
                        [_vm._v(" " + _vm._s(val.accessProtocolName) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "联网方式", prop: "delivery" } },
                [
                  _c(
                    "a-checkbox-group",
                    {
                      model: {
                        value: _vm.form.delivery,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "delivery", $$v)
                        },
                        expression: "form.delivery",
                      },
                    },
                    [
                      _c("a-checkbox", { attrs: { value: "蜂窝" } }, [
                        _vm._v(" 蜂窝 "),
                      ]),
                      _c("a-checkbox", { attrs: { value: "以太" } }, [
                        _vm._v(" 以太 "),
                      ]),
                      _c("a-checkbox", { attrs: { value: "NB" } }, [
                        _vm._v(" NB "),
                      ]),
                      _c("a-checkbox", { attrs: { value: "WIFI" } }, [
                        _vm._v(" WIFI "),
                      ]),
                      _c("a-checkbox", { attrs: { value: "其他" } }, [
                        _vm._v(" 其他 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { display: "flex" },
                  attrs: { label: "产品厂商", prop: "firm" },
                },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder:
                        "最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头",
                    },
                    model: {
                      value: _vm.form.firm,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "firm", $$v)
                      },
                      expression: "form.firm",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { display: "flex" },
                  attrs: { label: "产品型号", prop: "model" },
                },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder:
                        "最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头",
                    },
                    model: {
                      value: _vm.form.model,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "model", $$v)
                      },
                      expression: "form.model",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { display: "flex" },
                  attrs: { label: "产品描述", prop: "desc" },
                },
                [
                  _c("a-textarea", {
                    model: {
                      value: _vm.form.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "desc", $$v)
                      },
                      expression: "form.desc",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.modelTitle,
            footer: null,
            visible: _vm.modelVisible,
          },
          on: {
            cancel: function ($event) {
              _vm.modelVisible = false
            },
          },
        },
        [
          _c("a-table", {
            attrs: {
              columns: _vm.modelColumns,
              "data-source": _vm.modelData,
              locale: { emptyText: "暂无数据" },
              "row-key": "id",
              size: "middle",
              pagination: false,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }