<template>
    <a-config-provider :locale="locale">
        <a-pagination v-model="currentNum" :simple="simple" :total="count" :show-total="total => `共 ${total} 条`" show-size-changer show-quick-jumper @change="onChange" @showSizeChange="onChangeSize" />
    </a-config-provider>
</template>

<script>
// 分页汉化
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
    name: "Pagination",
    props: {
        count: {
            type: Number,
            default: 0
        },
        currentPage: {
            type: Number,
            default: 1
        },
        simple: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            locale: zhCN,
            currentNum: this.currentPage
        }
    },
    watch: {
        currentPage(val) {
            this.currentNum = val
        }
    },
    methods: {
        onChange(val) {
            this.$emit('handleChange', val)
        },
        onChangeSize(val, num) {
            this.$emit('handleChangeSize', num)
        }
    }
}
</script>

<style scoped>

</style>
